import React from 'react';
import {useRecoilState} from "recoil";
import {stepState, templateState} from "../../atoms/typeAtom";

const Step1 = () => {
    const [template, setTemplate] = useRecoilState(templateState);
    const [step, setStep] = useRecoilState(stepState);
    const type1 = () => {
        setTemplate(1);
        setStep(2);
    }
    const type2 = () => {
        setTemplate(2);
        setStep(2);
    }
    return (
        <div className={"mt-8"}>
            <h1 className={"flex text-center text-3xl justify-center mb-3 uppercase font-bold mb-8"}>Steg {step}/3</h1>
            <div
                className={`sm:mb-3 sm:grid lg:flex justify-center space-y-4 md:space-y-0 md:space-x-4 ${step === 1 ? 'transition-all duration-500 ease-in-out' : ''} `}>

                <div className="sm:mb-3 md:mb-0 max-w-xs overflow-hidden rounded-lg shadow-lg">
                    <div className="px-6 py-4 bg-white">
                        <h4 className="mb-3 text-xl font-bold tracking-tight text-sweden-blue">Converve</h4>
                        <p className="leading-normal text-gray-700 pb-3">Exportera en XLSX-fil från Converve och ladda upp den i sista steget.<br /><br /><br /><br /></p>
                        <button onClick={type1}
                                className={"uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-sweden-blue rounded-lg focus:shadow-outline hover:text-sweden-blue hover:bg-sweden-yellow"}>Välj
                            Converve
                        </button>
                    </div>
                </div>
                <div className="max-w-xs overflow-hidden rounded-lg shadow-lg">

                    <div className="px-6 py-4 bg-white">
                        <h4 className="mb-3 text-xl font-bold tracking-tight text-sweden-blue">Invajo</h4>
                        <p className="leading-normal text-gray-700 pb-3">Exportera en XLSX-fil från Converve och ladda upp den i sista steget. Kontrollera att det är en XLSX-fil och inte en vanlig XLS-fil. Spara annars om innan uppladdning.</p>
                        <button onClick={type2}
                                className={"uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-sweden-blue rounded-lg focus:shadow-outline hover:text-sweden-blue hover:bg-sweden-yellow"}>Välj
                            Invajo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step1;
