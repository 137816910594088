import React from 'react';
import {useRecoilState} from "recoil";
import {buyerState, headerState, stepState, subHeaderState, templateState} from "../../atoms/typeAtom";
//import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

const Step4 = () => {
    const [step, setStep] = useRecoilState(stepState);
    const [template, setTemplate] = useRecoilState(templateState);
    const [buyer, setBuyer] = useRecoilState(buyerState);
    const [header, setHeader] = useRecoilState(headerState);
    const [subHeader, setSubHeader] = useRecoilState(subHeaderState);

    return (
        <div className={"mt-8"}>
            <h1 className={"flex text-center text-3xl justify-center mb-3 uppercase font-bold mb-8"}>Klart!</h1>
            <p className={"flex text-center justify-center"}>Din fil har laddats ner på din dator!</p>

        </div>
    );
};

export default Step4;
