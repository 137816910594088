import React from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {settingsState, stepState} from "../../atoms/typeAtom";

function SettingsState () {

    const {actualTemplate, header,subheader, actualBuyer, file, setting} = useRecoilValue(settingsState);
    return(

        <ul className={"mx-auto rounded-md bg-white w-64 p-4"}>
            <li className={"font-bold text-sweden-blue uppercase"}>Inställningar:</li>
            {actualTemplate !== undefined && <li><strong>Mall:</strong> {actualTemplate}</li>}
            {actualBuyer !== undefined && <li><strong>Köpare/leverantör:</strong> {actualBuyer}</li>}
            {header !== '' && <li><strong>Rubrik:</strong> {header}</li>}
            {subheader !== '' && <li><strong>Underrubrik:</strong> {subheader}</li>}
            {setting !== '' && <li><strong>Inställningar:</strong> {setting}</li>}
        </ul>
    )
}
const Settings = () => {
    const [step, setStep] = useRecoilState(stepState);
    return (
        <div className={"mt-8 flex"}>
            {<SettingsState />}
        </div>
    );
};

export default Settings;
