import React from 'react';

const Footer = () => {
    return (
        <footer className={"flex md:mx-8"}>
                <div className={"w-1/2 items-baseline align-baseline"}>
                    <div className={"text-gray-900 items-end h-full flex pb-10"}>
                        <p className={"items-end"}>Copyright Ⓒ 2021 Visit Sweden</p>
                    </div>
                </div>
                <div className={"w-1/2"}>
                    <div className="Footer__SwedenLogotypes"><a className="Footer__SwedenLogotypes__Link"
                                                                href="https://www.sweden.se" target="_blank" rel="noopener">
                        <div className="Footer__SwedenLogotypes__Logo Footer__SwedenLogotypes__Logo--Sweden"><span
                            className="sr-only">Sweden.se</span></div>
                    </a>
                        <div className="Footer__SwedenLogotypes__Link Footer__SwedenLogotypes__Link--Second">
                            <div className="Footer__SwedenLogotypes__Logo Footer__SwedenLogotypes__Logo--VisitSweden"><span
                                className="sr-only">VisitSweden.com</span></div>
                        </div>
                    </div>
            </div>
        </footer>

        /*<footer className=" Footer bg-sweden-yellow mx-8">
            <div className="Footer__Container">
                <nav role="navigation" aria-labelledby="privacyFooterNav" className="Footer__PrivacyNav">
                    <div className="Footer__PrivacyNav__CopyrightNotice">Copyright Ⓒ 2021 Visit Sweden</div>
                </nav>
                <div className="Footer__SwedenLogotypes"><a className="Footer__SwedenLogotypes__Link"
                                                            href="https://www.sweden.se" target="_blank" rel="noopener">
                    <div className="Footer__SwedenLogotypes__Logo Footer__SwedenLogotypes__Logo--Sweden"><span
                        className="sr-only">Sweden.se</span></div>
                </a>
                    <div className="Footer__SwedenLogotypes__Link Footer__SwedenLogotypes__Link--Second">
                        <div className="Footer__SwedenLogotypes__Logo Footer__SwedenLogotypes__Logo--VisitSweden"><span
                            className="sr-only">VisitSweden.com</span></div>
                    </div>
                </div>
            </div>
        </footer>*/
    );
};

export default Footer;
