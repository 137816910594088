import React, {useRef, useState} from 'react';
import {useRecoilState} from "recoil";
import ReactLoading from 'react-loading';
import {buyerState, fileState, headerState, stepState, subHeaderState, templateState} from "../../atoms/typeAtom";

const Step3 = () => {
    const [step, setStep] = useRecoilState(stepState);
    const [template, setTemplate] = useRecoilState(templateState);
    const [buyer, setBuyer] = useRecoilState(buyerState);
    const [header, setHeader] = useRecoilState(headerState);
    const [subHeader, setSubHeader] = useRecoilState(subHeaderState);
    const [file, setFile] = useRecoilState(fileState);

    const form = useRef(null)

    const onFileChange = e => {
        setFile(e.target.files[0]);
    };
    const updateHeader = (e) => {
        setHeader(e.target.value);
    }

    function updateSubHeader(e) {
        setSubHeader(e.target.value);
    }

    const [loading, setLoading] = useState(false);

    const makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${makeid(7)}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const handleSubmit = e => {
        e.preventDefault()
        if(header === '' || subHeader === '' || file === null) {
            alert('Rubrik, underubrik eller fil saknas!');
            return;
        }
        setLoading(true);
        const data = new FormData();
        data.append("file",
            file,
            file.name);
        data.append("header", header);
        data.append("subheader", subHeader);
        data.append("template", template);
        data.append("buyer", buyer);

        fetch('https://d2b809z156avzc.cloudfront.net/pdf', {method: 'POST', body: data})
        // fetch('http://localhost:5001/pdf', {method: 'POST', body: data})
        //fetch('http://localhost:5000/pdf', { method: 'POST', body: data })
            .then(res => res.json())
            .then(json => {
                console.log('data', json);
                downloadPDF(json.file);
                setLoading(false);
                setStep(4);
            });
    }

    const content = () => {
        if (!loading) {
            return (<div className={"mt-8"}>
                    <h1 className={"flex text-center text-3xl justify-center mb-3 uppercase font-bold mb-8"}>Steg {step}/3</h1>
                    <div className={""}>
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className={"flex sm:justify-center"}>
                                <div className={"sm:flex-none md:flex-1"}/>
                                <div className="flex-1 justify-center -mx-3 mb-6">
                                    <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-2xl text-sweden-blue bg-sweden-yellow p-1 font-bold mb-2"
                                            htmlFor="grid-first-name">
                                            Rubrik
                                        </label>
                                        <input name={"header"} onChange={updateHeader}
                                               className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3  px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                               id="grid-first-name" type="text" placeholder="Swedish Workshop 2022"/>
                                    </div>
                                    <div className="w-full md:w-full px-3 mb-5">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xl text-sweden-blue font-bold mb-2 bg-sweden-yellow p-1"
                                            htmlFor="grid-last-name">
                                            Underrubrik
                                        </label>
                                        <input name={"subheader"} onChange={updateSubHeader}
                                               className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
                                               id="grid-last-name" type="text" placeholder="Suppliers product manual"/>
                                    </div>
                                    <div className="w-full md:w-full px-3 mb-5">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xl text-sweden-blue font-bold mb-2 bg-sweden-yellow p-1"
                                            htmlFor="grid-last-name">
                                            Excel-fil
                                        </label>
                                        <input name={"file"} onChange={onFileChange}
                                               className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
                                               id="grid-last-name" type="file" placeholder="Excel-fil"/>
                                    </div>
                                    <button type={"submit"}
                                            className={"uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-sweden-blue rounded-lg focus:shadow-outline hover:text-sweden-blue hover:bg-sweden-yellow"}>Skapa
                                        PDF
                                    </button>
                                </div>
                                <div className={"sm:flex-none md:flex-1"}/>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={"flex text-center justify-center"}>
                        <div className={"flex-row"}>
                            <ReactLoading type={"spin"} color="#fff"/>
                        </div>
                    </div>
                    <div className={"flex text-center justify-center mt-3"}>
                        <p>Skapar PDF...</p>
                    </div>
                </div>
            )
        }
    }

    return content();


};

export default Step3;
