import './css/app.css';
import {useRecoilState} from "recoil";
import {buyerState, stepState, templateState} from "./atoms/typeAtom";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Settings from "./components/Settings";
import Step4 from "./components/Step4";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {

    const [template, setTemplate] = useRecoilState(templateState);
    const [step, setStep] = useRecoilState(stepState);
    const [buyer, setBuyer] = useRecoilState(buyerState);

    return (
        <div className={"flex flex-col h-screen justify-between"}>
            <div className={"container  lg:mx-auto sm:mx-8"}>
                <Header/>
            </div>
            <div className={"flex lg:mx-8 mb-auto h-full"}>
                <div className={"flex w-full px-4 bg-hiking bg-contain bg-bottom p-5"}>
                    <div className="container mx-auto p-5">
                        {/*<h1 className={"text-6xl text-center font-bold pt-5"}>Välkommen</h1>*/}
                        {step === 1 && <Step1/>}
                        {step === 2 && <Step2/>}
                        {step === 3 && <Step3/>}
                        {step === 4 && <Step4/>}

                        {step !== 1 && step !== 4 && <Settings />}

                    </div>
                </div>
            </div>
            <div className={"bg-sweden-yellow mt-8"}>
                <div className={"container lg:mx-auto sm:mx-8"}>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default App;
