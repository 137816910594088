import {atom, selector} from "recoil";

const templateState = atom(
    {
        key: 'templateState',
        default: undefined
    });

const buyerState = atom({
    key: 'buyerState',
    default: undefined
});

const stepState = atom({
    key: 'stepState',
    default: 1
});

const headerState = atom({
    key: 'headerState',
    default: ''
});

const subHeaderState = atom({
    key: 'subHeaderState',
    default: ''
});
const fileState = atom( {
    key: 'fileState',
    default: null
});

const settingState = atom( {
    key: 'settingState',
    default: null
});

const settingsState = selector( {
    key: 'settingsState',
    get: ({get}) => {
        const template = get(templateState);
        const header = get(headerState);
        const subheader = get(subHeaderState);
        const buyer = get(buyerState);
        const file = get(fileState);
        const setting = get(settingState);
        let actualTemplate = undefined;
        let actualBuyer = undefined;

        if(template !== undefined) {
           actualTemplate = template === 1 ? 'Converve' : 'Invajo'
        }

        if(buyer !== undefined) {
            actualBuyer = buyer === 1 ? 'Köpare' : 'Leverantör';
        }

        return {
            actualTemplate,
            header,
            subheader,
            actualBuyer,
            file,
            setting
        };
    },
});

export {templateState, stepState, buyerState, headerState, subHeaderState, settingsState, fileState, settingState};