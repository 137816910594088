import React from 'react';

const Header = () => {
    return (
        <div className="headroom-wrapper sm:mx-8 md:mx-8" style={{height: '82px'}}>
            <div className="headroom headroom--unfixed headroom-disable-animation">
                <nav role="navigation" className="Navigation Navigation--HomePage">
                    <div className="Navigation__Container">
                        <div className="Navigation__Logotype" />
                        <div className="Navigation__MenuWrap">
                            <div className="Navigation__Byline text-xs text-gray-600">Manual PDF-generator
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;
