import React from 'react';
import {useRecoilState} from "recoil";
import {stepState, buyerState} from "../../atoms/typeAtom";

const Step2 = () => {
    const [section, setSection] = useRecoilState(buyerState);
    const [step, setStep] = useRecoilState(stepState);

    const buyer = () => {
        setSection(1);
        setStep(3);
    }
    const seller = () => {
        setSection(2);
        setStep(3);
    }
    return (
        <div className={"mt-8"}>
            <h1 className={"flex text-center text-3xl justify-center mb-3 uppercase font-bold mb-8"}>Steg {step}/3</h1>
            <div
                className={`sm:grid lg:flex justify-center space-y-4 md:space-y-0 md:space-x-4 ${step === 2 ? 'transition-all duration-500 ease-in-out' : ''} `}>

                <div className="max-w-xs overflow-hidden rounded-lg shadow-lg">
                    <div className="px-6 py-4 bg-white">
                        <h4 className="mb-3 text-xl font-bold tracking-tight text-sweden-blue">Köpare</h4>
                        <p className="leading-normal text-gray-700 pb-3">Sätter automatiskt <strong>Buyer</strong> i rubriken på indexsidan.</p>
                        <button onClick={buyer}
                                className={"uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-sweden-blue rounded-lg focus:shadow-outline hover:text-sweden-blue hover:bg-sweden-yellow"}>Välj
                            köpare
                        </button>
                    </div>
                </div>
                <div className="max-w-xs overflow-hidden rounded-lg shadow-lg">
                    <div className="px-6 py-4 bg-white">
                        <h4 className="mb-3 text-xl font-bold tracking-tight text-sweden-blue">Leverantör</h4>
                        <p className="leading-normal text-gray-700 pb-3">Sätter automatiskt <strong>Supplier</strong> i rubriken på indexsidan.</p>
                        <button onClick={seller}
                                className={" uppercase w-full h-12 px-6 text-white transition-colors duration-150 bg-sweden-blue rounded-lg focus:shadow-outline hover:text-sweden-blue hover:bg-sweden-yellow"}>Välj säljare
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step2;
